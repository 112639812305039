<template>
  <MDatePicker
    id="date-picker"
    ref="datePickerRef"
    :get-popup-container="popupContainer"
    :value="convertedValue"
    :show-time="showTime"
    :min-date="convertedMinValue"
    :max-date="convertedMaxValue"
    :default-picker-value="todayWithTimezone"
    :placeholder="placeholder || $t('select')"
    v-bind="$attrs"
    :date-format="dateFormat"
    :time-format="showTimeFormat"
    v-on="listeners"
    @change="handleDateChange"
  />
</template>

<script>
import Bus from '@utils/emitter'
import Moment from 'moment'
import { authComputed } from '@state/modules/auth'
import { OrganizationComputed } from '@/src/state/modules/organization'
export default {
  name: 'FlotoDatePicker',
  model: { event: 'change' },
  props: {
    // eslint-disable-next-line
    showTime: { type: Boolean, default: true },
    value: { type: [Number, Object, String], default: undefined },
    placeholder: { type: String, default: undefined },
    minDate: {
      type: [String, Number],
      default: undefined,
    },
    maxDate: {
      type: [String, Number],
      default: undefined,
    },
    endOfDay: { type: Boolean, default: false },
    focusEventBrodcast: { type: Boolean, default: false },
  },
  computed: {
    ...authComputed,
    ...OrganizationComputed,
    timezone() {
      if (this.user && this.user.timezone) {
        return this.user.timezone
      }
      return Moment.tz.guess()
    },
    todayWithTimezone() {
      return Moment().tz(this.timezone)
    },
    convertedValue() {
      if (this.value) {
        return Moment.tz(this.value, this.timezone)
      }
      return this.value
    },
    convertedMinValue() {
      if (this.minDate) {
        // return (
        //   Moment.tz(Moment.unix(this.minDate / 1000), timezone).unix() * 1000
        // )
        return Moment.tz(this.minDate, this.timezone)
      }
      return this.minDate
    },
    convertedMaxValue() {
      if (this.maxDate) {
        // return (
        //   Moment.tz(Moment.unix(this.maxDate / 1000), timezone).unix() * 1000
        // )
        return Moment.tz(this.maxDate, this.timezone)
      }
      return this.maxDate
    },
    timeFormat() {
      let tFormat
      if ((this.organization || {}).timeFormat) {
        tFormat = (this.organization || {}).timeFormat
      }
      if ((this.user || {}).timeFormat) {
        tFormat = (this.user || {}).timeFormat
      }
      return tFormat
    },
    dateFormat() {
      let dFormat
      if ((this.organization || {}).dateFormat) {
        dFormat = (this.organization || {}).dateFormat
      }
      if ((this.user || {}).dateFormat) {
        dFormat = (this.user || {}).dateFormat
      }
      if (this.showTime) {
        return `${dFormat} ${this.timeFormat}`
      }
      return dFormat
    },
    showTimeFormat() {
      if (this.showTime) {
        return {
          use12Hours: (this.timeFormat || '').indexOf('A') >= 0,
          format: this.timeFormat,
        }
      }
      return undefined
    },
    listeners() {
      const { change, ...listeners } = this.$listeners
      return listeners
    },
  },
  created() {
    if (this.focusEventBrodcast) {
      const openPopover = (id) => {
        if (id === this.$attrs.id) {
          this.$refs.datePickerRef.show()
        } else {
          this.$refs.datePickerRef.hide()
        }
      }
      const closePopover = (id) => {
        if (id === this.$attrs.id) {
          this.$refs.datePickerRef.hide()
        }
      }
      Bus.$on('app:popover:broadcast:open', openPopover)
      Bus.$on('app:popover:broadcast:close', closePopover)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('app:popover:broadcast:open', openPopover)
        Bus.$off('app:popover:broadcast:close', closePopover)
      })
    }
  },
  methods: {
    popupContainer() {
      const element = this.$attrs['get-popup-container']
        ? this.$attrs['get-popup-container']()
        : this.focusEventBrodcast
        ? this.$el.closest('.single-control')
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    handleDateChange(unix, m, ...args) {
      if (m) {
        // const timezone = this.user.timezone
        // const timeWithoutTimezone = Moment.tz(
        //   m.format('YYYY-MM-DD HH:mm:ss'),
        //   timezone
        // )
        // const timezonedValue = this.showTime
        //   ? timeWithoutTimezone
        //       .tz('utc')
        //       .second(0)
        //       .unix() * 1000
        //   : timeWithoutTimezone
        //       .startOf('day')
        //       .tz('utc')
        //       .second(0)
        //       .unix() * 1000
        // const timezonedValue = Moment(m.toJSON()).unix() * 1000
        const timezonedValue = this.showTime
          ? m.second(0).unix() * 1000
          : this.endOfDay
          ? m.endOf('day').unix() * 1000 // specific condition for end of day value
          : m.startOf('day').unix() * 1000
        this.$emit('change', timezonedValue, m, ...args)
        this.$emit('blur')
      } else {
        this.$emit('change', 0)
        this.$emit('blur')
      }
    },
  },
}
</script>
