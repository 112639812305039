var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default",function(){return [(_vm.clickable)?_c('a',{attrs:{"target":"_blank","href":_vm.valueItem
          ? _vm.$router.resolve(
              _vm.$modules.getModuleRoute('asset', 'view', {
                params: {
                  id: _vm.valueItem.id,
                  assetType: _vm.moduleName,
                },
              })
            ).href
          : undefined}},[(_vm.valueItem && _vm.value)?_c('span',[_vm._v(" "+_vm._s(_vm.showOnlyName ? ("" + (_vm.valueItem.name)) : ((_vm.valueItem.name) + " " + (_vm.valueItem.displayName)))+" ")]):_c('span',{staticClass:"text-neutral-dark"},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])]):_c('FlotoDropdownPicker',{attrs:{"disabled":_vm.disabled,"options":[],"show-no-data":false},scopedSlots:_vm._u([{key:"trigger",fn:function(triggerSlotData){return [_c('DropdownTrigger',{attrs:{"toggle":triggerSlotData.toggle,"disabled":_vm.disabled,"title":_vm.valueItem ? ((_vm.valueItem.name) + " " + (_vm.valueItem.displayName)) : '',"selected-item":_vm.valueItem
              ? {
                  text: ((_vm.valueItem.name) + " " + (_vm.valueItem.displayName)),
                  key: _vm.valueItem.id,
                }
              : {},"allow-clear":_vm.allowClear,"as-input":_vm.asInput,"is-open":false,"placeholder":_vm.placeholder},on:{"click":_vm.handleTriggerClick,"reset":_vm.handleResert}})]}}])})]}),_c('FlotoDrawer',{attrs:{"open":_vm.showDrawer,"width":"55%"},on:{"hide":_vm.hideDrawer},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.placeholder)+" ")]},proxy:true},{key:"actions",fn:function(ref){
              var hide = ref.hide;
return [_c('MButton',{staticClass:"mr-2",attrs:{"id":"add-btn","disabled":_vm.selectedItems.length === 0,"outline":"","loading":_vm.processing},on:{"click":_vm.handleSubmitSelection}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}}])},[_c('AssetSelectionList',{attrs:{"hide-search-selector":"","selectable":"","fetch-fn":_vm.fetchAssetsFn,"module-name":_vm.moduleName,"searchable":"","exclude-items-by-id":_vm.excludeItemsById,"max-allowed-selection":1},on:{"selection-change":_vm.setSelectedItems}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }