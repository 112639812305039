<template>
  <img
    v-if="/\d+$/.test(name)"
    :src="name"
    :style="{ 'max-height': size === 'lg' ? '24px' : '20px' }"
    v-bind="$attrs"
  />
  <div
    v-else
    class="custom-icon-container flex"
    :class="{
      ['w-6']: size === 'lg',
      ['w-5']: size === 'md',
      ['w-4']: size === 'sm',
      ['w-3']: size === 'xs',
    }"
    v-bind="$attrs"
  >
    <component :is="Icons[name]" v-if="Icons[name]" class="w-full" />
  </div>
</template>

<script>
import * as Icons from '@modules/asset/icons'
export default {
  name: 'CustomIcon',
  props: {
    name: { type: String, required: true },
    size: {
      type: String,
      default() {
        return 'lg'
      },
    },
  },
  data() {
    this.Icons = Icons
    return {}
  },
}
</script>
